import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomerSSidebar from "../component/CustomerSSidebar";
import Header from "../component/Header";

const apiUrl = process.env.REACT_APP_API_URL;

function CustomSummary() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const sortByOrderNumber = (data) => {
    return data
      .filter(
        (item) => item.ourStat === "Unpaid" || item.ourStat === "Received"
      ) // Keep only matching items
      .sort((a, b) => a.ourStat.localeCompare(b.ourStat)); // Sort alphabetically by 'ourStat'
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/items`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
      }));
      const sortedData = sortByOrderNumber(fetchedData);

      setData(sortedData); // Set the sorted data
      setFilteredData(sortedData); // Initialize filteredData with all products
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
    total();
  }, []);

  const total = () => {
    let sum = 0;

    data.forEach((item) => {
      const price = Number(item.Price) || 0;
      const com1 = Number(item.com1) || 0;
      const com2 = Number(item.com2) || 0;
      const deliveryFee = Number(item.DeliveryFee) || 0;

      sum += price + com1 + com2 + deliveryFee;
    });

    return sum;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`; // Format as 'YYYY-MM-DD'
  };

  return (
    <>
      <Header />
      <CustomerSSidebar />
      <div className="items-center flex flex-col justify-center mt-1 overflow-auto">
        <div className=" border-1 border-gray-500 mt-20 w-3/4 mb-20 p-5 rounded-md overflow-auto">
          <h1 className="font-bold text-2xl text-gray-800">Unpaid</h1>
          <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
            <thead>
              <tr>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                  #
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-300">
                  Brand
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                  Model
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Storage
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Ram
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Sim
                </th>
                {/* <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                Price
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-300">
                Delivery Fee
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                Total Price
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-300">
                Total
              </th> */}
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Buyer
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Sold Price
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Date
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-100">
                      {item.OrderNumber}
                    </td>
                    <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                      {item.Brand}
                    </td>
                    <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                      {item.Model}
                    </td>
                    <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                      {item.Storage}
                    </td>
                    <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                      {item.Ram}
                    </td>
                    <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                      {item.Sim}
                    </td>
                    {/* <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-100">
                    {item.Price}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                    {item.DeliveryFee}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-100">
                    {totalPriceForRow()}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                    {totalSellingPriceForRow()}
                  </td> */}
                    <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                      {item.buyer}
                    </td>
                    <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                      {item.soldPrice}
                    </td>
                    <td
                      className={`border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100`}
                    >
                      {formatDate(item.salesDate)}
                    </td>
                    <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                      {item.ourStat}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex flex-row">
            <h1 className="text-2xl font-bold mr-2">Total Unpaid: </h1>
            <p className="text-2xl underline"> {filteredData.length}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomSummary;
