import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./AttendanceCalendar.css";
import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState({});
  const [filteredAttendance, setFilteredAttendance] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Month filter
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Year filter
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isTimeSetterModalOpen, setIsTimeSetterModalOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState("");
  const [entryTime, setEntryTime] = useState("");
  const [leavingTime, setLeavingTime] = useState("");
  const [loading, setLoading] = useState(false);

  const [isRecordsModalOpen, setIsRecordsModalOpen] = useState(false);
  const employees = ["Yeabkal", "Natnael", "Yeabtsega","Mihret"]; // Example employees

    const fetchAllAttendanceData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/attendance`);
        const attendanceArray = response.data;

        const transformedData = {};
        attendanceArray.forEach((record) => {
          const recordDate = record.date.split("T")[0];
          if (!transformedData[recordDate]) {
            transformedData[recordDate] = [];
          }
          transformedData[recordDate].push({
            id: record.ID,
            userName: record.userName,
            entryTime: record.entryTime,
            leavingTime: record.leavingTime,
          });
        });

        setAttendanceData(transformedData);
        setFilteredAttendance(transformedData); // Initialize filtered data
      } catch (error) {
        console.error("Failed to fetch attendance data", error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {

    fetchAllAttendanceData();
  }, []);

  // Filter by selected month and year
  useEffect(() => {
    if (!selectedMonth && !selectedYear) {
      setFilteredAttendance(attendanceData);
      return;
    }

    const filteredData = {};
    Object.entries(attendanceData).forEach(([date, records]) => {
      const recordDate = new Date(date);
      const recordMonth = recordDate.getMonth(); // 0-indexed
      const recordYear = recordDate.getFullYear();

      if (
        (selectedMonth === "" || recordMonth === parseInt(selectedMonth, 10)) &&
        recordYear === parseInt(selectedYear, 10)
      ) {
        filteredData[date] = records;
      }
    });

    setFilteredAttendance(filteredData);
  }, [selectedMonth, selectedYear, attendanceData]);

  const handleSaveAttendance = async () => {
    if (!currentEmployee) {
      alert("Please select an employee.");
      return;
    }
  
    if (!entryTime && !leavingTime) {
      alert("Please enter at least an Entry Time or a Leaving Time.");
      return;
    }
  
    const formattedDate = selectedDate.toLocaleDateString("en-CA");
  
    // Find if this employee already has an entry for this date
    const existingRecord = attendanceData[formattedDate]?.find(
      (record) => record.userName === currentEmployee
    );
    console.log("updatedAttendance",existingRecord);
  
    try {
      if (existingRecord) {
        // If entry time exists, update leaving time only
        const updatedAttendance = {
          id: existingRecord.id, // Backend should require ID to update
          leavingTime: leavingTime || existingRecord.leavingTime, // Update only if new value provided
        };
        await axios.put(`${apiUrl}/api/attendance`, updatedAttendance, {
          headers: { "Content-Type": "application/json" },
        });
      } else {
        // New record with entry time
        const newAttendance = {
          date: formattedDate,
          userName: currentEmployee,
          entryTime,
          leavingTime,
        };

        await axios.post(`${apiUrl}/api/attendance`, newAttendance, {
          headers: { "Content-Type": "application/json" },
        });
      }
      setCurrentEmployee("");
      setEntryTime("");
      setLeavingTime("");
      fetchAllAttendanceData();
      setIsTimeSetterModalOpen(false);
    } catch (error) {
      console.error("Failed to save attendance", error);
    }
  };

  const renderTileContent = ({ date }) => {
    const formattedDate = date.toLocaleDateString("en-CA");
    const records = filteredAttendance[formattedDate];
    if (!records) return null;

    return (
      <span className="absolute bottom-0 left-1/2 mt-1 transform -translate-x-1/2 text-xs text-red-300">
        {Object.keys(records).length}
      </span>
    );
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setIsRecordsModalOpen(true);

    const formattedDate = date.toLocaleDateString("en-CA");
    const records = attendanceData[formattedDate];
    if (!records) {
      console.warn(`No attendance data found for ${formattedDate}`);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="flex flex-col justify-center align-middle">
        <div className="attendance-calendar-container">
          <h1>Employee Attendance</h1>

          <Calendar
            view="month"
            maxDetail="month"
            minDetail="month"
            onClickDay={handleDateClick}
            value={selectedDate}
            tileContent={renderTileContent}
          />
          {isRecordsModalOpen && (
            <div className="modal">
              <h2>Attendance Records for {selectedDate.toDateString()}</h2>
              <div className="record-list">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {attendanceData[
                      selectedDate.toLocaleDateString("en-CA")
                    ] ? (
                      Object.entries(
                        attendanceData[selectedDate.toLocaleDateString("en-CA")]
                      ).map(([employee, times]) => (
                        <div key={employee} className="record-item">
                          <strong>{times.userName}</strong>:{" "}
                          <strong>Entered at</strong> {times.entryTime} -{" "}
                          <strong>Leaving at</strong> {times.leavingTime}
                        </div>
                      ))
                    ) : (
                      <p>No attendance records for this date.</p>
                    )}
                  </>
                )}
              </div>

              <div className="modal-actions">
                <button
                  onClick={() => setIsTimeSetterModalOpen(true)}
                  className="add-btn"
                >
                  Add Attendance
                </button>
                <button
                  onClick={() => setIsRecordsModalOpen(false)}
                  className="close-btn"
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {isTimeSetterModalOpen && (
            <div className="modal">
              <h2>Add Attendance for {selectedDate.toDateString()}</h2>
              <div className="modal-field">
                <label>Employee:</label>
                <select
                  value={currentEmployee}
                  onChange={(e) => setCurrentEmployee(e.target.value)}
                >
                  <option value="">Select Employee</option>
                  {employees.map((emp) => (
                    <option key={emp} value={emp}>
                      {emp}
                    </option>
                  ))}
                </select>
              </div>
              <div className="modal-field">
                <label>Entry Time:</label>
                <input
                  type="time"
                  value={entryTime}
                  onChange={(e) => setEntryTime(e.target.value)}
                />
              </div>
              <div className="modal-field">
                <label>Leaving Time:</label>
                <input
                  type="time"
                  value={leavingTime}
                  onChange={(e) => setLeavingTime(e.target.value)}
                />
              </div>
              <div className="modal-actions">
                <button onClick={handleSaveAttendance} className="save-btn">
                  Save
                </button>
                <button
                  onClick={() => setIsTimeSetterModalOpen(false)}
                  className="cancel-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          <div className="attendance-list mt-5">
            <h2 className="text-xl font-bold mb-4">Attendance Records</h2>
            {/* Month and Year Filter */}
            <div className="flex gap-4 mb-4">
              <div>
                <label
                  htmlFor="month-filter"
                  className="block text-sm font-medium"
                >
                  Filter by Month:
                </label>
                <select
                  id="month-filter"
                  className="mt-1 block w-40 p-2 border border-gray-300 rounded-md"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  <option value="">All Months</option>
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={i}>
                      {new Date(0, i).toLocaleString("en-US", {
                        month: "long",
                      })}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  htmlFor="year-filter"
                  className="block text-sm font-medium"
                >
                  Filter by Year:
                </label>
                <select
                  id="year-filter"
                  className="mt-1 block w-40 p-2 border border-gray-300 rounded-md"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {Array.from(
                    { length: 5 }, // Generate last 5 years
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Sorted Attendance Records */}
            {loading ? (
              <p>Loading...</p>
            ) : Object.keys(filteredAttendance).length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {Object.entries(filteredAttendance)
                  // Sort dates in descending order
                  .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
                  .map(([date, records]) => (
                    <div
                      key={date}
                      className="p-4 bg-slate-100 rounded-lg shadow-md border border-slate-200"
                    >
                      <h3 className="text-lg font-semibold mb-2 text-slate-700">
                        {new Date(date).toDateString()}
                      </h3>
                      <ul className="space-y-2">
                        {records.map((record, index) => (
                          <li key={index} className="text-sm text-slate-600">
                            <strong>{record.userName}</strong>: Entered at{" "}
                            <span className="text-green-500">
                              {record.entryTime}
                            </span>
                            , Left at{" "}
                            <span className="text-red-500">
                              {record.leavingTime}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
            ) : (
              <p>No attendance records available.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance;
