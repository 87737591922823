import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

function CustomerSSidebar() {
  const [isOpen, setIsOpen] = useState(false);

  // Sidebar links
  const sidebarLinks = [
    { href: "/CustomSummary", label: "Summary" },
    { href: "/CustomerService", label: "Customer Service" },
    { href: "/ChangePassword", label: "Change Password" },
  ];

  return (
    <>
      {/* Button to toggle sidebar */}
      <button
        className="relative top-5 left-5 bg-black text-white p-2 z-50 rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-20 py-16 left-0 h-full w-3/4 sm:w-1/4 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-40 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <nav className="flex flex-col pt-30">
          {sidebarLinks.map((link) => (
            <a
              key={link.href}
              href={link.href}
              className="p-4 mx-4 border-b rounded hover:bg-gray-300"
            >
              {link.label}
            </a>
          ))}
        </nav>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </>
  );
}

export default CustomerSSidebar;
