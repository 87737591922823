import React, { useState } from "react";
import axios from "axios";
import Sidebar from "../component/Sidebar";
import Header from "../component/Header";

const apiUrl = process.env.REACT_APP_API_URL;

function Register() {
  const [Password, setPassword] = useState("");
  const [UserName, setUserName] = useState("");
  const [Role, setRole] = useState("");
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setPassword("");
    setUserName("");
    setRole("");
  };
  const handleRegister = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/register`, {
        Password,
        UserName,
        Role,
      });

      if (response) {
        setLoading(false);
        alert("successful");
      } else {
        setLoading(false);
      }
      reset();
    } catch (error) {
      reset();
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="flex justify-center items-center h-screen w-full">
        <div className="w-1/2 h-auto flex flex-col justify-between items-center rounded-xl py-5 bg-slate-100">
          <h1 className="font-bold text-2xl text-black">Register</h1>
          <input
            type="text"
            className="w-3/5 m-4 p-2 rounded"
            placeholder="Username"
            value={UserName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <input
            type="password"
            className="w-3/5 m-4 p-2 rounded"
            placeholder="Password"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <select
            name="role"
            id="role"
            className="w-3/5 m-4 p-2 rounded"
            value={Role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="Role" disabled>
              Role
            </option>
            <option value="China">China</option>
            <option value="Admin">Admin</option>
            <option value="CustomerService">Customer Service</option>
            <option value="Finance">Finance</option>
            <option value="Shipment">Shipment</option>
            <option value="Delivery">Delivery</option>
            <option value="User">User</option>
          </select>
          <button
            className="w-3/5 bg-zinc-700 m-4 p-2 rounded text-white font-bold hover:bg-zinc-600"
            onClick={handleRegister}
            disabled={loading}
          >
            {loading ? "Registering..." : "Register"}
          </button>
        </div>
      </div>
    </>
  );
}

export default Register;
