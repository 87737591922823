import React, { useState } from "react";
import axios from "axios";
import Header from "../component/Header";
import CustomerSSidebar from "../component/CustomerSSidebar";
import Sidebar from "../component/Sidebar";

const apiUrl = process.env.REACT_APP_API_URL;

function ChangePass() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const admin = localStorage.getItem("role") === "Admin";
  const user = localStorage.getItem("role") === "CustomerService";

  const handleChange = async () => {
    setLoading(true);
    if (newPassword !== confirmNewPassword) {
      alert("New passwords do not match");
      setLoading(false);
      return;
    }
    try {
      const userId = localStorage.getItem("token");
      const response = await axios.post(
        `${apiUrl}/change-password`,
        {
          oldPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${userId}`,
          },
        }
      );
      if (response.status === 200) {
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setErrors(null);
        alert("Password changed successfully");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(
        "An error occurred while changing the password:",
        error.response?.data || error.message
      );
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      <Header />
      {admin && <Sidebar />}
      {user && <CustomerSSidebar />}
      <div className="justify-center content-center flex flex-col h-screen">
        <div className="flex flex-col w-1/3 self-center content-center bg-gray-200 p-4 m-4 rounded-xl">
          <h1 className="font-bold text-xl">Change Password</h1>
          <input
            type="text"
            placeholder="Old Password"
            onChange={(e) => setOldPassword(e.target.value)}
            value={oldPassword}
            className="bg-gray-100 p-4 m-4"
          />
          <input
            type="text"
            placeholder="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
            className="bg-gray-100 p-4 m-4"
          />
          <input
            type="text"
            placeholder="Confirm New Password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            value={confirmNewPassword}
            className="bg-gray-100 p-4 m-4"
          />
          <button
            className="bg-zinc-700 m-4 p-2 rounded text-white font-bold hover:bg-zinc-600"
            onClick={handleChange}
            disabled={loading}
          >
            {loading ? "Changing..." : "Change"}
          </button>
          <p className="text-red-500 self-center">{errors}</p>
        </div>
      </div>
    </>
  );
}

export default ChangePass;
