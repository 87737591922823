import React from "react";
import EtTable from "../component/EtTable";
import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import SoldPhones from "../component/SoldPhones";
import Unpaid from "../component/Unpaid";
import DailyUpdate from "../component/DailyUpdate";
import FinanceTable from "../component/FinanceTable";
import People from "../component/People";
import TotalSent from "./TotalSent";
import Repair from "../component/Repair";

function Summary() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="flex flex-col">
        <EtTable />
        <Unpaid />
        <DailyUpdate />
        <Repair/>
        <People />
        <FinanceTable />
        <TotalSent />
        <SoldPhones />
      </div>
    </div>
  );
}

export default Summary;
