import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

function Unpaid() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null); // Track which row is being edited
  const [soldPrice, setSoldPrice] = useState({}); // Store editable sold prices
  const [isEditing, setIsEditing] = useState(false);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => a.buyer.localeCompare(b.buyer));
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/unpaid`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
      }));
      const sortedData = sortByOrderNumber(fetchedData);

      setData(sortedData); // Set the sorted data
      setFilteredData(sortedData); // Initialize filteredData with all products
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
    total();
  }, []);

  const total = () => {
    let sum = 0;

    data.forEach((item) => {
      const price = Number(item.Price) || 0;
      const com1 = Number(item.com1) || 0;
      const com2 = Number(item.com2) || 0;
      const deliveryFee = Number(item.DeliveryFee) || 0;

      sum += price + com1 + com2 + deliveryFee;
    });

    return sum;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`; // Format as 'YYYY-MM-DD'
  };

  const updateStatus = async (itemId, ourStat) => {
    const userId = localStorage.getItem("token");
    try {
      setLoading(true);
      const res = await axios.put(`${apiUrl}/updateOurStat`, {
        id: itemId,
        ourStat,
      },
      {
        headers: {
          Authorization: `Bearer ${userId}`,
        },
      });

      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOptionSelect = (index, itemId, ourStat) => {
    setDropdownOpen(null); // Close the dropdown
    // Update the status in the frontend
    setData(
      data.map((item) =>
        item.ID === itemId ? { item, ourStat: ourStat } : item
      )
    );
    // Update the status in the backend
    updateStatus(itemId, ourStat);
    // fetchData();
  };

  const handleEdit = (itemId, currentSoldPrice) => {
    setEditingRowId(itemId);
    setSoldPrice((prev) => ({
      ...prev,
      [itemId]: currentSoldPrice || "",
    }));
  };

  const handleBlur = async (itemId) => {
    setEditingRowId(null);
    const userId = localStorage.getItem("token");
    // Save the updated price to the backend
    try {
      await axios.put(
        `${apiUrl}/updateSoldPrice`,
        {
          id: itemId,
          price: soldPrice[itemId],
        },
        {
          headers: {
            Authorization: `Bearer ${userId}`,
          },
        }
      );

      fetchData(); // Refresh data after update
    } catch (error) {
      console.error("Failed to update price", error);
    }
  };

  const handleKeyDown = (e, itemId) => {
    if (e.key === "Enter") {
      handleBlur(itemId); // Save when Enter is pressed
    }
  };

  return (
    <div className="items-center flex flex-col justify-center mt-1 overflow-auto">
      <div className=" border-1 border-red-500 mt-20 w-3/4 mb-20 p-5 rounded-md overflow-auto">
        <h1 className="font-bold text-2xl text-red-800">Unpaid</h1>
        <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
          <thead>
            <tr>
              <th className="border-b-1 pb-3 border-slate-600 text-left bg-red-200">
                #
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left bg-red-300">
                Brand
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left bg-red-200">
                Model
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-300">
                Storage
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                Ram
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-300">
                Sim
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                Price
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-300">
                Delivery Fee
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                Total Price
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-300">
                Total
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                Buyer
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-300">
                Sold Price
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                Profit
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-300">
                Date
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              const totalPriceForRow = () => {
                const price = Number(item.Price) || 0;
                const com1 = Number(item.com1) || 70;
                const com2 = Number(item.com2) || 40;
                const deliveryFee = Number(item.DeliveryFee) || 0;
                const sum = price + com1 + com2 + deliveryFee;
                return sum.toLocaleString();
              };
              const totalSellingPriceForRow = () => {
                const price = Number(item.Price) || 0;
                const com1 = Number(item.com1) || 70;
                const com2 = Number(item.com2) || 40;
                const deliveryFee = Number(item.DeliveryFee) || 0;
                const rate = Number(item.rate) || 0;
                const carrier = Number(item.carrier) || 1500;
                const sum =
                  (price + com1 + com2 + deliveryFee) * rate + carrier;
                return sum.toLocaleString();
              };
              const profit = () => {
                const totalPrice = Number(
                  totalSellingPriceForRow().replace(/,/g, "")
                ); // Convert to a number by removing commas
                const soldPrice = Number(item.soldPrice) || 0;
                const sum = soldPrice - totalPrice;

                return sum.toLocaleString(); // Return profit in a formatted string
              };

              return (
                <tr key={index}>
                  <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-red-100">
                    {item.OrderNumber}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                    {item.Brand}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-100">
                    {item.Model}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                    {item.Storage}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-100">
                    {item.Ram}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                    {item.Sim}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-100">
                    {item.Price}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                    {item.DeliveryFee}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-100">
                    {totalPriceForRow()}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-200">
                    {totalSellingPriceForRow()}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-100">
                    {item.buyer}
                  </td>
                  <td
                    className="border-b-1 pb-3 border-slate-600 text-left bg-red-200 cursor-pointer"
                    onClick={() => handleEdit(item.ID, item.soldPrice)}
                  >
                    {editingRowId === item.ID ? (
                      <input
                        type="number"
                        className="w-full border-1"
                        value={soldPrice[item.ID] || ""}
                        onChange={(e) =>
                          setSoldPrice((prev) => ({
                            ...prev,
                            [item.ID]: e.target.value,
                          }))
                        }
                        onBlur={() => handleBlur(item.ID)}
                        onKeyDown={(e) => handleKeyDown(e, item.ID)}
                        autoFocus
                      />
                    ) : (
                      item.soldPrice || "Click to edit"
                    )}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-100">
                    {profit()}
                  </td>
                  <td
                    className={`border-b-1 pb-3 border-slate-600 text-left m-0 ${
                      new Date(item.salesDate) <
                      new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
                        ? "bg-red-500 text-white"
                        : "bg-red-200"
                    }`}
                  >
                    {formatDate(item.salesDate)}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-red-100">
                    <div className="relative inline-block text-left">
                      <div className="flex flex-row justify-around">
                        <button
                          type="button"
                          className={`inline-flex justify-around gap-x-1.5 rounded-md w-20 mr-1 py-2 text-sm font-semibold shadow-sm hover:bg-gray-500
                                            ${
                                              item.ourStat === "Sold"
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Received"
                                                ? "bg-blue-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Sent"
                                                ? "bg-yellow-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Returned"
                                                ? "bg-purple-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Unpaid"
                                                ? "bg-red-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Custom"
                                                ? "bg-black text-white"
                                                : ""
                                            }
                                            ${
                                              !item.ourStat
                                                ? "bg-gray-100 text-blue-900"
                                                : ""
                                            }`}
                          id="menu-button"
                          aria-expanded={dropdownOpen === index}
                          aria-haspopup="true"
                          onClick={() => toggleDropdown(index)}
                        >
                          {item.ourStat || "options"}
                          <svg
                            className="-mr-1 h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                      {dropdownOpen === index && (
                        <div
                          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                        >
                          <div className="py-1">
                            <button
                              className="block w-full px-4 py-2 text-left text-sm text-gray-700 "
                              role="menuitem"
                              tabIndex="-1"
                              onClick={() =>
                                handleOptionSelect(index, item.ID, "Sold")
                              }
                            >
                              Paid
                            </button>
                            <button
                              className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                              role="menuitem"
                              tabIndex="-1"
                              onClick={() =>
                                handleOptionSelect(index, item.ID, "Received")
                              }
                            >
                              Returned
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex flex-row">
          <h1 className="text-2xl font-bold mr-2">Total Unpaid: </h1>
          <p className="text-2xl underline"> {filteredData.length}</p>
        </div>
      </div>
    </div>
  );
}

export default Unpaid;
