import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      setLoading(true);
      const user = { username: userName, password: password };
      const response = await axios.post(`${apiUrl}/login`, user);

      const { token, role } = response.data;

      // Store the token in localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
      localStorage.setItem("user", userName);

      // Role-based navigation
      switch (role) {
        case "China":
          navigate("/china");
          break;
        case "CustomerService":
          navigate("/customerService");
          break;
        case "Finance":
          navigate("/finance");
          break;
        case "Shipment":
          navigate("/shipment");
          break;
        case "Admin":
          navigate("/admin");
          break;
        case "User":
          navigate("/summary");
          break;
        default:
          navigate("/");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="h-screen w-screen content-center">
      <div className="w-1/2 h-50 flex flex-col justify-between align-middle self-center bg-slate-100  rounded-xl py-5 mx-auto">
        <h1 className="flex self-center font-bold text-2xl text-black">Login</h1>
        <input
          type="text"
          className="w-3/5 m-4 self-center p-2 rounded"
          placeholder="Username"
          onChange={(e) => setUserName(e.target.value)}
        />
        <input
          type="password"
          className="w-3/5 m-4 self-center p-2 rounded"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button
          className="w-3/5 bg-zinc-700 m-4 self-center p-2 rounded text-white font-bold hover:bg-zinc-600"
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? "Logging in..." : "Login"}
        </button>
        <p className="text-red-500 text-center">{errors}</p>
      </div>
    </div>
  );
}

export default Login;
