import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import noImage from "../images/errors.png";
import { Oval } from "react-loader-spinner";
import OtherProducts from "../component/OtherProducts";

const apiUrl = process.env.REACT_APP_API_URL;

function Admin() {
  const userId = localStorage.getItem("token");
  const [zoom, setZoom] = useState(1);
  const [totalPrice, setTotalPrice] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterDay, setFilterDay] = useState("");
  const [filterStatusP, setFilterStatusP] = useState("");
  const [filterModel, setFilterModel] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [imageFile1, setImageFile1] = useState(null); // For the fir st image
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [totalSent, setTotalSent] = useState("");
  const [totalReceivedCal, setTotalReceivedCal] = useState("");
  const [totalOrder, setTotalOrder] = useState("");
  const [sortBy, setSortBy] = useState("orderNumber"); // Default sorting

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Added for year filter

  let totalYuanSum = 0;
  let totalProfit = 0;

  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (imageUrls) => {
    setImages(imageUrls);
    setCurrentIndex(0); // Start from the first image
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage("");
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const dropdownRef = useRef(null);
  // Define short forms for months
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get current month in short form
  const getCurrentMonthShortForm = () => {
    const monthIndex = new Date().getMonth();
    return months[monthIndex];
  };

  const sortByModelName = (data) => {
    return [...data].sort((a, b) => a.Model.localeCompare(b.Model));
  };

  const sortByOrderNumber = (data) => {
    return [...data].sort((a, b) => {
      const [aMonth, aNumber] = [
        a.OrderNumber.slice(0, 3),
        parseInt(a.OrderNumber.slice(3), 10),
      ];
      const [bMonth, bNumber] = [
        b.OrderNumber.slice(0, 3),
        parseInt(b.OrderNumber.slice(3), 10),
      ];
      return aMonth !== bMonth
        ? bMonth.localeCompare(aMonth)
        : bNumber - aNumber;
    });
  };

  const applySorting = (data, sortBy) => {
    switch (sortBy) {
      case "model":
        return sortByModelName(data);
      case "orderNumber":
        return sortByOrderNumber(data);
      default:
        return data; // No sorting applied
    }
  };

  const filterDataByMonth = (data, month) => {
    return data.filter((item) => item.OrderNumber.startsWith(month));
  };
  const filterDataByYear = (data, year) => {
    return data.filter((item) => {
      const itemYear = new Date(item.TimeStamp).getFullYear();
      return itemYear === parseInt(year, 10);
    });
  };

  const applyFilters = (data, month, year) => {
    let filtered = filterDataByYear(data, year);
    filtered = filterDataByMonth(filtered, month);
    return filtered;
  };

  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth(); // Get current month index (0-11)
    return months[monthIndex];
  };
  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm());

  const filterByMonthAll = (month = getCurrentMonthShortForm()) => {
    setSelectedMonth(month);
    const filteredMonth = data.filter((item) =>
      item.OrderNumber.startsWith(month)
    );
    const filteredYear = filterDataByYear(filteredMonth, selectedYear);
    setFilteredData(filteredYear);

    const sum = filteredMonth.reduce(
      (acc, item) => acc + parseFloat(item.Price || 0),
      0
    );
    const receivedTotal = filteredMonth.filter(
      (item) => item.Status === "Received"
    ).length;
    const sentTotal = filteredMonth.filter(
      (item) => item.Status === "Sent"
    ).length;
    const ordered = filteredMonth.length;

    setTotalPrice(sum);
    setTotalReceivedCal(receivedTotal);
    setTotalSent(sentTotal);
    setTotalOrder(ordered);
  };

  // Fetch data from API and apply initial filter by month
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/items`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`,
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`,
      }));
      const sortedData = applySorting(fetchedData, sortBy);
      setData(sortedData);
      
      setFilteredData(
        filterDataByMonth(sortedData, selectedMonth, selectedYear)
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  // const handleMonthChange = (event) => {
  //   const month = event.target.value;
  //   setSelectedMonth(month);
  //   setFilteredData(filterDataByMonth(data, month));
  // };

  const updateStatus = async (itemId, ourStat) => {
    try {
      setLoading(true);
      const res = await axios.put(
        `${apiUrl}/updateOurStat`,
        {
          id: itemId,
          ourStat,
        },
        {
          headers: {
            Authorization: `Bearer ${userId}`,
          },
        }
      );

      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOptionSelect = (index, itemId, ourStat) => {
    setDropdownOpen(null); // Close the dropdown
    // Update the status in the frontend
    setData(
      data.map((item) =>
        item.ID === itemId ? { item, ourStat: ourStat } : item
      )
    );
    // Update the status in the backend
    updateStatus(itemId, ourStat);
    // fetchData();
  };
  const filterByMonthAndYear = (
    month = currentMonthShortForm(),
    year = new Date().getFullYear()
  ) => {
    setSelectedMonth(month);
    setSelectedYear(year);
    const filtered = applyFilters(data, month, year);
    setFilteredData(filtered);

    const sum = filtered.reduce(
      (acc, item) => acc + parseFloat(item.Price || 0),
      0
    );
    const receivedTotal = filtered.filter(
      (item) => item.Status === "Received"
    ).length;
    const sentTotal = filtered.filter((item) => item.Status === "Sent").length;
    const ordered = filtered.length;

    setTotalPrice(sum);
    setTotalReceivedCal(receivedTotal);
    setTotalSent(sentTotal);
    setTotalOrder(ordered);
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    filterByMonthAndYear(selectedMonth, year);
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    filterByMonthAndYear(month, selectedYear);
  };
  useEffect(() => {
    fetchData(sortBy);
  }, [sortBy]);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [initialData, setInitialData] = useState({}); // Store the initial row data when editing starts

  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({
    brand: "",
    model: "",
    sim: "",
    ram: "",
    storage: "",
    status: "",
    delivery: "",
    price: "",
    com1: "",
    com2: "",
    rate: "",
    carrier: "",
    buyer: "",
    seller: "",
    soldPrice: "",
    receiver: "",
    salesDate: "",
    buyRate: "",
  });

  const handleEditClick = (item) => {
    setIsEditing(item.ID);
    setInitialData({
      brand: item.Brand,
      model: item.Model,
      sim: item.Sim,
      ram: item.Ram,
      storage: item.Storage,
      status: item.Status,
      delivery: item.DeliveryFee,
      price: item.Price, // Add image URL to initial data
      com1: item.com1, // Add image URL to initial data
      com2: item.com2, // Add image URL to initial data
      rate: item.rate, // Add image URL to initial data
      carrier: item.carrier, // Add image URL to initial data
      buyer: item.buyer, // Add image URL to initial data
      seller: item.seller, // Add image URL to initial data
      soldPrice: item.soldPrice, // Add image URL to initial data
      receiver: item.receiver, // Add image URL to initial data
      salesDate: item.salesDate, // Add image URL to initial data
      buyRate: item.buyRate, // Add image URL to initial data
    });
    setEditData({}); // Start with an empty object
    setImageFile1(null);
    setImageFile1(null); // Start with no image
  };
  const [editImage1, setEditImage1] = useState(null);
  const [editImage2, setEditImage2] = useState(null);

  // Handle changes to text fields
  const handleChange = (field, value) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value !== initialData[field] ? value : undefined, // Set value if changed, otherwise undefined
    }));
  };

  // Function to handle saving the edited data
  const handleSave = async (id) => {
    try {
      setLoading(true);
      const completeEditData = createFormData();

      // Append image files if they are selected
      appendImages(completeEditData);

      // Make the PUT request
      const response = await axios.put(
        `${apiUrl}/updateItem/${id}`,
        completeEditData,
        {
          headers: {
            Authorization: `Bearer ${userId}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setData(data.map((item) => (item.ID === id ? response.data : item)));
      setIsEditing(null);
      setLoading(false);
      setEditImage1(null);
      setEditImage2(null);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const createFormData = () => {
    const completeEditData = new FormData();
    const fields = [
      "brand",
      "model",
      "sim",
      "ram",
      "price",
      "storage",
      "status",
      "delivery",
      "com1",
      "com2",
      "carrier",
      "rate",
      "buyRate",
      "seller",
      "buyer",
      "receiver",
      "soldPrice",
      "salesDate",
    ];

    fields.forEach((field) => {
      completeEditData.append(
        field,
        editData[field] !== undefined ? editData[field] : initialData[field]
      );
    });

    return completeEditData;
  };

  const appendImages = (formData) => {
    if (editImage1) {
      formData.append("images", editImage1); // First image
    }
    if (editImage2) {
      formData.append("images", editImage2); // Second image
    }
  };

  const filterByDay = (data) => {
    if (!filterDay) return data;
    return filteredData.filter((item) => {
      const itemDate = new Date(item.TimeStamp);
      const itemDay = itemDate.getDate();
      return itemDay === parseInt(filterDay, 10);
    });
  };

  const filterByStatus = (data) => {
    if (!filterStatusP) return data;
    return filteredData.filter((item) => item.ourStat === filterStatusP);
  };

  const filterByModel = (data) => {
    if (!filterModel) return data;
    return filteredData.filter(
      (item) =>
        item.Model &&
        item.Model.toLowerCase().includes(filterModel.toLowerCase())
    );
  };

  // Apply all filters in useEffect whenever filter values change
  useEffect(() => {
    let filtered = filteredData;
    filtered = filterByDay(filtered);
    filtered = filterByStatus(filtered);
    filtered = filterByModel(filtered);
    setFilteredData(filtered);
  }, [filterByDay, filterByStatus, filterByModel, filteredData]);

  const handleClear = () => {
    setFilterMonth("");
    setFilterDay("");
    setFilterStatusP("");
    setFilterModel("");
  };

  const handleMonthSelect = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    setFilteredData(filterDataByMonth(data, month)); // Update filtered data by month
  };
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`; // Format as 'YYYY-MM-DD'
  };
  const [dropdownOpenReceiver, setDropdownOpenReceiver] = useState(null);
  const [dropdownOpenSeller, setDropdownOpenSeller] = useState(null);

  const dropdownRefReceiver = useRef(null);
  const dropdownRefSeller = useRef(null);

  const toggleDropdownReceiver = (index) => {
    setDropdownOpenReceiver(dropdownOpenReceiver === index ? null : index);
  };

  const toggleDropdownSeller = (index) => {
    setDropdownOpenSeller(dropdownOpenReceiver === index ? null : index);
  };

  const handleOptionSelectReceiver = (index, itemId, receiver) => {
    setDropdownOpenReceiver(null); // Close the dropdown
    // Update the status in the frontend optimistically
    setData(
      data.map((item) =>
        item.ID === itemId ? { ...item, seller: receiver } : item
      )
    );
    console.log(itemId, receiver);
    // Update the status in the backend
    updateReceiver(itemId, receiver);
  };

  const handleOptionSelectSeller = (index, itemId, seller) => {
    setDropdownOpenSeller(null); // Close the dropdown
    // Update the status in the frontend optimistically
    setData(
      data.map((item) =>
        item.ID === itemId ? { ...item, seller: seller } : item
      )
    );
    // Update the status in the backend
    updateSeller(itemId, seller);
  };

  const updateReceiver = async (itemId, receiver) => {
    console.log("front",itemId,receiver)
    try {
      setLoading(true);
      await axios.put(
        `${apiUrl}/updateReceiver`,
        { id: itemId, receiver },
        {
          headers: {
            Authorization: `Bearer ${userId}`,
          },
        }
      );
      setLoading(false);
      // Fetch updated data after successful status update
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const updateSeller = async (itemId, seller) => {
    console.log("seller",itemId,seller)
    try {
      setLoading(true);
      await axios.put(
        `${apiUrl}/updateSeller`,
        { id: itemId, seller },
        {
          headers: {
            Authorization: `Bearer ${userId}`
          },
        }
      );
      setLoading(false);
      // Fetch updated data after successful status update
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const totalExpense = filteredData.reduce(
    (acc, item) => acc + Number(item.Price || 0),
    0
  );
  const totalCom1 = filteredData.reduce(
    (acc, item) => acc + Number(item.com1 || 0),
    0
  );
  const totalCom2 = filteredData.reduce(
    (acc, item) => acc + Number(item.com2 || 0),
    0
  );
  const totalDelivery = filteredData.reduce(
    (acc, item) => acc + Number(item.DeliveryFee || 0),
    0
  );

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="items-center flex flex-col justify-center mt-5 overflow-auto ">
        {loading ? <p>Loading</p> : null}
        {errors ? (
          <p className="bg-red-500 text-zinc-100 rounded px-5 py-2">{errors}</p>
        ) : null}
        <div className=" border-1 border-slate-200 mt-20 h-screen w-11/12 mb-20 px-5 rounded-md overflow-auto">
          <div className="flex flex-col my-2">
            <h1 className="text-3xl font-bold text-gray-800">Products</h1>
          </div>
          {isMobile ? (
            <div className="flex flex-col my-2">
              {/* <select
                  value={filterMonth}
                  onChange={(e) => handleMonthSelect(e.target.value)}
                  className="border p-2 mr-2 my-2"
                >
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select> */}
              <select
                name="filterDay"
                id="filterDay"
                className="border p-2 mr-2 my-2"
                value={selectedMonth}
                onChange={(e) => filterByMonthAll(e.target.value)}
              >
                <option value="" disabled>
                  Filter by Month
                </option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
                <option value="">All</option>
              </select>
              <div className="flex flex-row border p-2 mr-2 my-2">
                <label htmlFor="year-select" className="mr-3">
                  Year:
                </label>
                <input
                  id="year-select"
                  type="number"
                  value={selectedYear}
                  onChange={handleYearChange}
                  min="2000"
                  max={new Date().getFullYear()}
                />
              </div>
              <select
                name="filterDay"
                id="filterDay"
                className="border p-2 mr-2 my-2"
                value={filterDay}
                onChange={(e) => setFilterDay(e.target.value)}
                disabled={!selectedMonth}
              >
                <option value="" disabled>
                  Filter by Day
                </option>
                {selectedMonth &&
                  Array.from(
                    {
                      length: new Date(
                        new Date().getFullYear(),
                        months.indexOf(filterMonth) + 1,
                        0
                      ).getDate(),
                    },
                    (_, i) => i + 1
                  ).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
              </select>
              <select
                name="filterStatusP"
                id="filterStatusP"
                className="border p-2 mr-2 mb-2"
                value={filterStatusP}
                onChange={(e) => setFilterStatusP(e.target.value)}
              >
                <option value="" disabled>
                  Filter by Status
                </option>
                <option value="Ordered">Ordered</option>
                <option value="Received">Received</option>
                <option value="Sent">Sent</option>
                <option value="Return Received">Return Received</option>
                <option value="Return Sent">Return Sent</option>
              </select>
              <input
                className="border border-spacing-1 p-2 mr-2 mb-2"
                type="text"
                placeholder="Filtered by Model"
                value={filterModel}
                onChange={(e) => setFilterModel(e.target.value)}
              />
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className="border rounded p-2"
              >
                <option value="orderNumber">Sort by Order Number</option>
                <option value="model">Sort by Model Name</option>
              </select>
              <button
                onClick={handleClear}
                className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 my-2 rounded"
              >
                Clear
              </button>
            </div>
          ) : (
            <div className="flex flex-row m-0 p-0">
              <div className="w-3/4 mb-5">
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  className="border rounded p-2"
                >
                  <option value="orderNumber">Sort by Order Number</option>
                  <option value="model">Sort by Model Name</option>
                </select>

                <select
                  name="filterDay"
                  id="filterDay"
                  className="border p-2 m-2"
                  value={selectedMonth}
                  onChange={(e) => filterByMonthAll(e.target.value)}
                >
                  <option value="" disabled>
                    Filter by Month
                  </option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                  <option value="">All</option>
                </select>
                <label htmlFor="month-select" className="font-bold mr-2">
                  Select Month:
                </label>
                <select
                  id="month-select"
                  onChange={handleMonthChange}
                  value={selectedMonth}
                >
                  <option value="">All</option>
                  <option value="Jan">January</option>
                  <option value="Feb">February</option>
                  <option value="Mar">March</option>
                  <option value="Apr">April</option>
                  <option value="May">May</option>
                  <option value="Jun">June</option>
                  <option value="Jul">July</option>
                  <option value="Aug">August</option>
                  <option value="Sep">September</option>
                  <option value="Oct">October</option>
                  <option value="Nov">November</option>
                  <option value="Dec">December</option>
                </select>
                <label htmlFor="year-select" className="mx-3 font-bold">
                  Year:
                </label>
                <input
                  id="year-select"
                  type="number"
                  value={selectedYear}
                  onChange={handleYearChange}
                  min="2000"
                  max={new Date().getFullYear()}
                />
              </div>
              <select
                name="filterDay"
                id="filterDay"
                className="border p-0 mr-0 my-0"
                value={filterDay}
                onChange={(e) => setFilterDay(e.target.value)}
                disabled={!selectedMonth}
              >
                <option value="" disabled>
                  Filter by Day
                </option>
                {selectedMonth &&
                  Array.from(
                    {
                      length: new Date(
                        new Date().getFullYear(),
                        months.indexOf(filterMonth) + 1,
                        0
                      ).getDate(),
                    },
                    (_, i) => i + 1
                  ).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
              </select>
              <select
                name="filterStatusP"
                id="filterStatusP"
                className="border p-0 m-0"
                value={filterStatusP}
                onChange={(e) => setFilterStatusP(e.target.value)}
              >
                <option value="" disabled>
                  Filter by Status
                </option>
                <option value="Ordered">Ordered</option>
                <option value="Received">Received</option>
                <option value="Sent">Sent</option>
                <option value="Return Received">Return Received</option>
                <option value="Return Sent">Return Sent</option>
              </select>
              <input
                className="border p-0 mr-0 mb-0"
                type="text"
                placeholder="Filtered by Model"
                value={filterModel}
                onChange={(e) => setFilterModel(e.target.value)}
              />

              <button
                onClick={handleClear}
                className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 rounded"
              >
                Clear
              </button>
            </div>
          )}
          {/* Conditionally render the select for mobile screens */}
          {isMobile ? (
            <div className="flex flex-row my-3">
              {/* <select
                  name="filterDay"
                  id="filterDay"
                  className="border p-2 mr-2 my-2"
                  value={selectedMonth}
                  onChange={(e) => filterByMonthAll(e.target.value)}
                >
                  <option value="" disabled>
                    Filter by Month
                  </option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                  <option value="">All</option>
                </select> */}
            </div>
          ) : (
            <div className="flex justify-center my-3">
              {months.map((month) => (
                <button
                  key={month}
                  onClick={() => filterByMonthAll(month)}
                  className={`px-4 py-2 rounded-md mx-2 ${
                    selectedMonth === month
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {month}
                </button>
              ))}
              <button
                onClick={() => filterByMonthAll("")}
                className={`px-4 py-2 rounded-md mx-2 ${
                  selectedMonth === ""
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200"
                }`}
              >
                All
              </button>
            </div>
          )}
          <div className="relative max-h-[80vh] overflow-y-auto custom-scrollbar">
            <table className="w-full border-collapse">
              <thead className="sticky top-0">
                <tr>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    #
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Brand
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Model
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Storage
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Ram
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Sim
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Price
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Delivery Fee
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Com1
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Com2
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Total ¥
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Rate
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    BuyRate
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Carrier
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Total ETB
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                    Profit
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Images
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Buyer
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Price
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Seller
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Receiver
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Store Stat
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                    Date
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Action
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    Editor
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => {
                  const price = Number(item.Price) || 0;
                  const com1 = Number(item.com1) || 0;
                  const com2 = Number(item.com2) || 0;
                  const deliveryFee = Number(item.DeliveryFee) || 0;

                  const totalYuan = () => {
                    const sum = price + com1 + com2 + deliveryFee;
                    totalYuanSum += sum;
                    return Math.round(sum * 10) / 10;
                  };

                  const totalBirr = () => {
                    const rate = Number(item.rate) || 20;
                    const carrier = Number(item.carrier) || 1500;
                    const sum =
                      (price + com1 + com2 + deliveryFee) * rate + carrier;
                    return Math.round(sum * 100) / 100;
                  };

                  const profit = () => {
                    const profitValue = item.soldPrice - totalBirr();
                    totalProfit += profitValue; // Accumulate total profit
                    return Math.round(profitValue * 100) / 100;
                  };

                  return (
                    <tr key={item.ID} className="border-b-1">
                      {isEditing === item.ID ? (
                        <>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {item.OrderNumber}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.brand !== undefined
                                  ? editData.brand
                                  : item.Brand
                              }
                              onChange={(e) =>
                                handleChange("brand", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.model !== undefined
                                  ? editData.model
                                  : item.Model
                              }
                              onChange={(e) =>
                                handleChange("model", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.storage !== undefined
                                  ? editData.storage
                                  : item.Storage
                              }
                              onChange={(e) =>
                                handleChange("storage", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.ram !== undefined
                                  ? editData.ram
                                  : item.Ram
                              }
                              onChange={(e) =>
                                handleChange("ram", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.sim !== undefined
                                  ? editData.sim
                                  : item.Sim
                              }
                              onChange={(e) =>
                                handleChange("sim", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.price !== undefined
                                  ? editData.price
                                  : item.Price
                              }
                              onChange={(e) =>
                                handleChange("price", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.delivery !== undefined
                                  ? editData.delivery
                                  : item.DeliveryFee
                              }
                              onChange={(e) =>
                                handleChange("delivery", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.com1 !== undefined
                                  ? editData.com1
                                  : item.com1
                              }
                              onChange={(e) =>
                                handleChange("com1", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.com2 !== undefined
                                  ? editData.com2
                                  : item.com2
                              }
                              onChange={(e) =>
                                handleChange("com2", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {totalYuan()}
                          </td>

                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.rate !== undefined
                                  ? editData.rate
                                  : item.rate
                              }
                              onChange={(e) =>
                                handleChange("rate", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.buyRate !== undefined
                                  ? editData.buyRate
                                  : item.buyRate
                              }
                              onChange={(e) =>
                                handleChange("buyRate", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <input
                              className="border w-full"
                              type="text"
                              value={
                                editData.carrier !== undefined
                                  ? editData.carrier
                                  : item.carrier
                              }
                              onChange={(e) =>
                                handleChange("carrier", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {totalBirr()}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {profit()}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-700 bg-gray-100">
                            {item.ImageFilename1 !== "" ||
                            item.ImageFilename2 !== "" ? (
                              <div>
                                {/* Image upload inputs */}
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    setEditImage1(e.target.files[0])
                                  }
                                  accept="image/*"
                                />
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    setEditImage2(e.target.files[0])
                                  }
                                  accept="image/*"
                                />
                              </div>
                            ) : (
                              <div>
                                {/* Image upload inputs */}
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    setEditImage1(e.target.files[0])
                                  }
                                  accept="image/*"
                                />
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    setEditImage2(e.target.files[0])
                                  }
                                  accept="image/*"
                                />
                              </div>
                            )}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <input
                              className="border w-full"
                              type="text"
                              placeholder="buyer"
                              value={
                                editData.buyer !== undefined
                                  ? editData.buyer
                                  : item.buyer
                              }
                              onChange={(e) =>
                                handleChange("buyer", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            <input
                              className="border w-full"
                              type="text"
                              placeholder="price"
                              value={
                                editData.soldPrice !== undefined
                                  ? editData.soldPrice
                                  : item.soldPrice
                              }
                              onChange={(e) =>
                                handleChange("soldPrice", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <input
                              className="border w-full"
                              type="text"
                              placeholder="seller"
                              value={
                                editData.seller !== undefined
                                  ? editData.seller
                                  : item.seller
                              }
                              onChange={(e) =>
                                handleChange("seller", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            <input
                              className="border w-full"
                              type="text"
                              placeholder="receiver"
                              value={
                                editData.receiver !== undefined
                                  ? editData.receiver
                                  : item.receiver
                              }
                              onChange={(e) =>
                                handleChange("receiver", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <div className="relative inline-block text-left">
                              <div className="flex flex-row justify-around">
                                <button
                                  type="button"
                                  className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                                  id="menu-button"
                                  aria-expanded={dropdownOpen === index}
                                  aria-haspopup="true"
                                >
                                  {item.ourStat || "Options"}
                                </button>
                              </div>
                              {dropdownOpen === index && (
                                <div
                                  className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  aria-orientation="vertical"
                                  aria-labelledby="menu-button"
                                  tabIndex="-1"
                                >
                                  <div className="py-1">
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Received"
                                        )
                                      }
                                    >
                                      Received
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Sent"
                                        )
                                      }
                                    >
                                      Sent
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Ren"
                                        )
                                      }
                                    >
                                      Returned
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            <input
                              type="date"
                              placeholder={formatDate(item.salesDate)}
                              value={
                                editData.salesDate !== undefined
                                  ? editData.salesDate
                                  : item.salesDate
                              }
                              onChange={(e) =>
                                handleChange("salesDate", e.target.value)
                              }
                            />
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {loading ? (
                              <button className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50">
                                <Oval
                                  height={20}
                                  width={20}
                                  color="#fff"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#4fa94d"
                                  strokeWidth={2}
                                  strokeWidthSecondary={2}
                                />
                              </button>
                            ) : (
                              <button
                                className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                                onClick={() => handleSave(item.ID)}
                              >
                                Save
                              </button>
                            )}
                            <button
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-red-300"
                              onClick={() => setIsEditing(null)}
                            >
                              Cancel
                            </button>
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {item.editor_name}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-100">
                            {item.OrderNumber}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {item.Brand}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {item.Model}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {item.Storage}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {item.Ram}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {item.Sim}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {item.Price}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {item.DeliveryFee}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {item.com1}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {item.com2}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {totalYuan()}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {item.rate}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {item.buyRate}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {item.carrier}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {totalBirr()}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {profit()}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-700 bg-gray-100 z-0">
                            {item.ImageFilename1 || item.ImageFilename2 ? (
                              <div className="flex flex-row gap-2 h-10">
                                {[item.imageUrl1, item.imageUrl2]
                                  .filter(Boolean)
                                  .map((img, index) => (
                                    <img
                                      key={index}
                                      src={img}
                                      alt=""
                                      height="20"
                                      width="20"
                                      className="cursor-pointer"
                                      onClick={() =>
                                        openModal(
                                          [
                                            item.imageUrl1,
                                            item.imageUrl2,
                                          ].filter(Boolean)
                                        )
                                      }
                                    />
                                  ))}
                              </div>
                            ) : (
                              <img
                                src={noImage}
                                alt="No Image Available"
                                height="10"
                                width="50"
                                className="cursor-pointer"
                                onClick={() => openModal([noImage])}
                              />
                            )}
                          </td>

                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            {item.buyer}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {item.soldPrice}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50 z-0">
                            <div className="relative inline-block text-left z-0">
                              <div className="flex flex-row justify-around z-0">
                                <button
                                  type="button"
                                  className="inline-flex justify-around z-0 gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                                  id="menu-button-receiverr"
                                  aria-expanded={dropdownOpenSeller === index}
                                  aria-haspopup="true"
                                  onClick={() => toggleDropdownSeller(index)}
                                >
                                  {item.seller || "select"}
                                  <svg
                                    className="-mr-1 h-5 w-5 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                              {dropdownOpenSeller === index && (
                                <div
                                  className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  aria-orientation="vertical"
                                  aria-labelledby="menu-button-receiverr"
                                  tabIndex="-1"
                                >
                                  <div className="py-1" ref={dropdownRefSeller}>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelectSeller(
                                          index,
                                          item.ID,
                                          "Yeabkal"
                                        )
                                      }
                                    >
                                      Yeabkal
                                    </button>

                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelectSeller(
                                          index,
                                          item.ID,
                                          "Natnael"
                                        )
                                      }
                                    >
                                      Natnael
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelectSeller(
                                          index,
                                          item.ID,
                                          "Yeabtsega"
                                        )
                                      }
                                    >
                                      Yeabtsega
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            <div className="relative inline-block text-left">
                              <div className="flex flex-row justify-around">
                                <button
                                  type="button"
                                  className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                                  id="menu-button-receiverr"
                                  aria-expanded={dropdownOpenReceiver === index}
                                  aria-haspopup="true"
                                  onClick={() => toggleDropdownReceiver(index)}
                                >
                                  {item.receiver || "select"}
                                  <svg
                                    className="-mr-1 h-5 w-5 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                              {dropdownOpenReceiver === index && (
                                <div
                                  className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  aria-orientation="vertical"
                                  aria-labelledby="menu-button-receiverr"
                                  tabIndex="-1"
                                >
                                  <div
                                    className="py-1"
                                    ref={dropdownRefReceiver}
                                  >
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelectReceiver(
                                          index,
                                          item.ID,
                                          "Yeabkal"
                                        )
                                      }
                                    >
                                      Yeabkal
                                    </button>

                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelectReceiver(
                                          index,
                                          item.ID,
                                          "Natnael"
                                        )
                                      }
                                    >
                                      Natnael
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelectReceiver(
                                          index,
                                          item.ID,
                                          "Yeabtsega"
                                        )
                                      }
                                    >
                                      Yeabtsega
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <div className="relative inline-block text-left">
                              <div className="flex flex-row justify-around">
                                <button
                                  type="button"
                                  className={`inline-flex justify-around gap-x-1.5 rounded-md w-20 mr-1 py-2 text-sm font-semibold shadow-sm hover:bg-gray-500
                                            ${
                                              item.ourStat === "Sold"
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Received"
                                                ? "bg-blue-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Sent"
                                                ? "bg-yellow-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Returned"
                                                ? "bg-purple-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Unpaid"
                                                ? "bg-red-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Custom"
                                                ? "bg-black text-white"
                                                : ""
                                            }
                                            ${
                                              !item.ourStat
                                                ? "bg-gray-100 text-blue-900"
                                                : ""
                                            }`}
                                  id="menu-button"
                                  aria-expanded={dropdownOpen === index}
                                  aria-haspopup="true"
                                  onClick={() => toggleDropdown(index)}
                                >
                                  {item.ourStat || "options"}
                                  <svg
                                    className="-mr-1 h-5 w-5 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                              {dropdownOpen === index && (
                                <div
                                  className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  aria-orientation="vertical"
                                  aria-labelledby="menu-button"
                                  tabIndex="-1"
                                >
                                  <div className="py-1">
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700 "
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Sold"
                                        )
                                      }
                                    >
                                      Sold
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Received"
                                        )
                                      }
                                    >
                                      Received
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Custom"
                                        )
                                      }
                                    >
                                      Custom
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Returned"
                                        )
                                      }
                                    >
                                      Returned
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Repair"
                                        )
                                      }
                                    >
                                      Repair
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Unpaid"
                                        )
                                      }
                                    >
                                      Unpaid
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(
                                          index,
                                          item.ID,
                                          "Unknown"
                                        )
                                      }
                                    >
                                      Unknown
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                      role="menuitem"
                                      tabIndex="-1"
                                      onClick={() =>
                                        handleOptionSelect(index, item.ID, "")
                                      }
                                    >
                                      clear
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>

                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {formatDate(item.salesDate)}
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-50">
                            <button
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-200 w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                              onClick={() => handleEditClick(item)}
                            >
                              Edit
                            </button>
                          </td>
                          <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                            {item.editor_name}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
                <tr>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200 font-bold">
                    Total
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    -
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    -
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    -
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    -
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    -
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200 font-bold">
                    {totalExpense}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100 font-bold">
                    {totalDelivery}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200 font-bold">
                    {totalCom1}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100 font-bold">
                    {totalCom2}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200 font-bold">
                    {totalYuanSum}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    -
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    -
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    -
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    -
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100 font-bold">
                    {totalProfit}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <OtherProducts />
        {/* Modal for full-screen display */}
        {showModal && (
          <div
            className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10"
            onClick={closeModal} // Clicking the background closes the modal
          >
            <div
              className="rounded-lg shadow-lg max-w-lg relative bg-white p-2 pt-6"
              onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside modal
            >
              {/* Close Button */}
              <button
                className="absolute right-2 top-1 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
                // className="bg-red-500 px-2  text-white rounded-md hover:bg-red-600"
                onClick={closeModal}
              >
                x
              </button>

              {/* Image */}
              <img
                src={images[currentIndex]}
                alt="Preview"
                className="max-w-full max-h-[80vh] rounded-lg transition-transform duration-200 cursor-pointer"
                style={{ transform: `scale(${zoom})` }}
                onWheel={(e) =>
                  setZoom((prev) =>
                    Math.min(Math.max(prev + e.deltaY * -0.001, 1), 3)
                  )
                }
              />

              {/* Right Arrow */}
              {images.length > 1 && (
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
                  onClick={nextImage}
                >
                  ▶
                </button>
              )}
              {images.length > 1 && (
                <button
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
                  onClick={prevImage}
                >
                  ◀
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Admin;
